<template>
  <div class="container">
    <div class="header">
      <div>
        <!-- <Cascader
          transfer
          v-model="searchData.farmScene"
          :data="farmScenes"
          change-on-select
          :load-data="loadingScenes"
          @on-change="cascaderChange"
        ></Cascader> -->
        <!-- <Select @on-change="searchChange" style="width: 260px" clearable>
          <Option v-for="(item, index) in farmList" :value="item.id">{{
            item.name
          }}</Option>
        </Select> -->
        <Input
          v-model="farmName"
          placeholder="请选择养殖场"
          style="width: 200px"
          @on-focus="selFarm"
        ></Input>
      </div>
    </div>
    <div>
      <Row>
        <Col span="18"
          ><p style="font-size: 20px; font-weight: 600">用水量统计</p></Col
        >
        <Col span="2">
          <div class="filter-box" style="line-height: 30px">
            <span
              :class="[
                'filter-item',
                fertilizeType == 'day' ? 'filter-item-active' : ''
              ]"
              @click="() => monitorCut('day')"
              style="margin-right: 10px"
              >日</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'month' ? 'filter-item-active' : ''
              ]"
              @click="() => monitorCut('month')"
              >月</span
            >
            <span
              :class="[
                'filter-item',
                fertilizeType == 'year' ? 'filter-item-active' : ''
              ]"
              @click="() => monitorCut('year')"
              >年</span
            >
          </div>
        </Col>
        <Col span="3" push="1">
          <DatePicker
            type="daterange"
            placement="bottom-end"
            placeholder="请选择日期"
            style="width: 200px"
            @on-change="dateChange"
            v-model="time"
          ></DatePicker>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <div
            id="myChart"
            style="width: 100%; height: 300px; margin-bottom: 20px"
          ></div>
        </Col>
      </Row>
      <Row>
        <Col span="24">
          <Table :columns="columns" :data="data1"></Table>
        </Col>
      </Row>
      <div style="text-align: end">
        <Page :total="page.total" :pageSize="page.pageSize" show-total />
      </div>
    </div>

    <select-farm
      v-model="selModal"
      :farmId="farmId"
      :options="options"
      isDefault
      @sel-finish="selFinish"
    ></select-farm>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import selectFarm from "@/components/selectFarm";
export default {
  components: {
    selectFarm
  },
  data() {
    return {
      model1: [],
      searchData: {
        farmScene: []
      },
      // 基地场景
      farmScenes: [],
      //基地
      farmList: [],
      fertilizeType: "day",
      cityList: [],
      data: [],
      useAmountData: [],
      time: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      columns: [
        {
          title: "养殖场",
          render: (h, { row }) => {
            return <span>{row.farmName}</span>;
          }
        },
        {
          title: "养殖区",
          render: (h, { row }) => {
            return <span>{row.sceneName ? row.sceneName : "--"}</span>;
          }
        },
        {
          title: "用水量(m³)",
          key: "amount"
        },
        {
          title: "上报时间",
          key: "reportTime"
        }
      ],
      data1: [],
      farmId: "",
      farmName: "",
      selModal: false,
      options: {
        siteType: 2,
        //species: 3
      }
    };
  },
  watch: {
    farmId(n) {
      if (!n) return;
      this.data = [];
      this.useAmountData = [];
      this.getEchartsList();
      this.getWaterList();
    },
    time(curVal) {
      console.log(curVal);
      this.getEchartsList();
      this.getWaterList();
    }
  },
  created() {
    this.time.push(
      moment(new Date())
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    );
    this.time.push(moment().format("YYYY-MM-DD"));
    console.log(this.time);
  },
  methods: {
    selFarm() {
      this.selModal = true;
    },
    selFinish(farm) {
      this.farmId = farm.id;
      this.farmName = farm.name;
      this.selModal = false;
    },
    monitorCut(type) {
      if (this.fertilizeType == type) return;
      this.fertilizeType = type;
      console.log(this.fertilizeType);
      if (this.fertilizeType == "day") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(7, "days")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        console.log(this.time);
      }

      if (this.fertilizeType == "month") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(60, "days")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        this.$forceUpdate()
        console.log(this.time);
      }
      if (this.fertilizeType == "year") {
        this.time=[]
        this.time.push(
          moment(new Date())
            .subtract(2, "year")
            .format("YYYY-MM-DD")
        );
        this.time.push(moment().format("YYYY-MM-DD"));
        console.log(this.time);
      }
      this.getEchartsList();
      this.getWaterList()
    },
    //初始化Echarts
    initEcharts() {
      let myChart = echarts.init(document.getElementById("myChart"));
      myChart.setOption({
        grid: {
          top: 50,
          left: 50,
          right: 60,
          bottom: 20
        },
        tooltip: {
          trigger: "axis"
        },
        xAxis: {
          name: "时间段",
          data: this.data,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed"
            }
          },
          boundaryGap: false
        },
        yAxis: {
          name: "单位(m³)",
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6"
            }
          }
          // type:'value',
          //   axisLabel: {
          //     formatter: "{value}m³"
          //   }
        },
        color: ["#7A40F2"],
        series: {
          type: "line",
          smooth: true,
          data: this.useAmountData,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "rgba(122, 64, 242, 0.4)"
                },
                {
                  offset: 1,
                  color: "rgba(122, 64, 242, 0)"
                }
              ]
            }
          },
          lineStyle: {
            shadowOffsetY: 10,
            shadowColor: "rgba(122, 64, 242, 0.3)",
            shadowBlur: 4
          }
        }
      });
    },
    getEchartsList() {
      if (this.farmId == "") return;
      if (this.time.length < 2 || !this.time[0] || !this.time[1]) return;
      let type = this.fertilizeType;
      this.$post(this.$api.WATER_USAGE.TOTAL, {
        type: type,
        startTime: moment(this.time[0]).format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment(this.time[1]).format("YYYY-MM-DD") + " 23:59:59",
        farmId: this.farmId
      }).then(res => {
        // this.data1 = res.list;
        this.data = [];
        this.useAmountData = [];
        res.forEach(item => {
          this.data.push(item.name);
          this.useAmountData.push(item.value);
          console.log(this.data, "data");
          console.log(this.useAmountData, "this.useAmountData");
        });
        this.initEcharts();
      });
    },
    dateChange(val) {
      this.getEchartsList();
    },
    getWaterList() {
      if (this.farmId == "") return;
      this.$post(this.$api.WATER_USAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        farmId: this.farmId,
        startTime: moment(this.time[0]).format("YYYY-MM-DD") + " 00:00:00",
        endTime: moment(this.time[1]).format("YYYY-MM-DD") + " 23:59:59",
      }).then(res => {
        this.data1 = res.list;
        // console.log(this.data1,'')
        this.page.total = +res.total;
      });
    },
    // 获取基地场景
    getFarmScene() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 9999
      }).then(res => {
        this.farmScenes = res.list.map(item => ({
          value: item.id,
          label: item.name,
          children: [],
          loading: false
        }));
      });
    },
    getFarmList() {
      this.$post(this.$api.FAMRMING.FARMLIST, {
        siteType: 2,
        species: 3
      }).then(res => {
        this.farmList = res.list;
      });
    },
    // 获取养殖区
    loadingScenes(item, callback) {
      item.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        farmId: item.value,
        pageNo: 1,
        pageSize: 9999,
        siteType: "2"
      })
        .then(res => {
          if (!res.list || res.list.length === 0) {
            item.children = [
              {
                disabled: true,
                value: -1,
                label: "无"
              }
            ];
          } else {
            item.children = res.list.map(item => ({
              value: item.id,
              label: item.name
            }));
          }
        })
        .finally(() => {
          item.loading = false;
          callback();
        });
    },
    cascaderChange(value) {
      this.data = [];
      this.useAmountData = [];
      this.farmId = value[0];
      this.getEchartsList();
      this.getWaterList();
    },
    searchChange(val) {
      this.data = [];
      this.useAmountData = [];
      this.farmId = val;
      this.getEchartsList();
      this.getWaterList();
    }
  },
  mounted() {
    this.initEcharts();
    this.getEchartsList();
    this.getWaterList();
    // this.getFarmScene();
    // this.getFarmList();
  }
};
</script>

<style lang="less" scoped>
@import url("./index.less");
.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
</style>